const defaultSpecs = {
  apps: "https://api.divio.com/apps/v3/schema.json",
  iam: "https://api.divio.com/iam/v3/schema.json",
  legacy: "https://api.divio.com/legacy/v3/schema.json",
  billing: "https://api.divio.com/billing/v3/schema.json",
};
const windowEnv = window.ENV || {};

export const SPECS = process.env.REACT_APP_SPECS
  ? JSON.parse(process.env.REACT_APP_SPECS)
  : windowEnv.REACT_APP_SPECS
  ? JSON.parse(windowEnv.REACT_APP_SPECS)
  : defaultSpecs;
