import React, { useState, useEffect } from "react";
import { RedocStandalone } from "redoc";
import { withRouter, NavLink, Route, Redirect } from "react-router-dom";
import Helmet from "react-helmet";

import { SPECS } from "./config";
import "./app.css";

function App() {
  const [specs, setSpecs] = useState({});

  useEffect(() => {
    Object.keys(SPECS).forEach(async (key) => {
      const r = await fetch(SPECS[key]);
      const spec = await r.json();

      setSpecs((existing) => ({ ...existing, [SPECS[key]]: spec }));
    });
  }, []);

  return (
    <>
      <Helmet title="Divio Cloud API" />
      <Route
        path="/:path"
        render={(props) => {
          const specUrl = SPECS[props.match.params.path];

          if (!specUrl) {
            return <Redirect to={`/${Object.keys(SPECS)[0]}/`} />;
          }

          if (!specs[specUrl]) {
            return null;
          }

          return (
            <>
              <Helmet
                title={`${specs[specUrl].info.title} ${specs[specUrl].info.version}`}
              />
              <div className="header">
                <h1 className="header__title">
                  {specs[specUrl].info.title} {specs[specUrl].info.version}
                </h1>
                <div className="header__menu">
                  {Object.keys(SPECS).map((name) => (
                    <NavLink
                      className="header__link"
                      activeClassName="header__link--active"
                      key={name}
                      to={`/${name}/`}
                    >
                      {name.toUpperCase()}
                    </NavLink>
                  ))}
                </div>
              </div>
              <RedocStandalone
                options={{
                  nativeScrollbars: true,
                  scrollYOffset: 72,
                  theme: { colors: { primary: { main: "#1a406b" } } },
                }}
                key={specUrl}
                spec={specs[specUrl]}
              />
            </>
          );
        }}
      />
      <Redirect from="/" exact to={`/${Object.keys(SPECS)[0]}/`} />
    </>
  );
}

export default withRouter(App);
